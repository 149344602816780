module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './modules/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './layouts/**/*.{js,ts,jsx,tsx}',
    './ui/**/*.{js,ts,jsx,tsx}',
    './ui/**/*.stories.js|jsx|ts|tsx',
    './components/**/*.stories.js|jsx|ts|tsx',
  ],
  theme: {
    extend: {
      screens: {
        xs: '0px',
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1440px',
        'xs-only': {min: '0px', max: '599px'},
        'sm-only': {min: '600px', max: '959px'},
        devicehover: {raw: '(hover: hover)'},
      },
      backgroundImage: (theme) => ({
        'neon-gradient': `linear-gradient(280deg, ${theme(
          'colors.neon-green',
        )} 11.88%, ${theme('colors.neon-lime')} 48.02%)`,
      }),
      colors: {
        // Keep this sorted alphabetically
        black: '#000000',
        'blue-50': '#E1F5FF',
        'blue-100': '#BDE3FF',
        'blue-200': '#94C5FF',
        'blue-300': '#D3EEFF',
        'blue-500': '#4E8AF4',
        'blue-600': '#4C7CE5',
        'blue-700': '#496AD0',
        'blue-800': '#4559BD',
        'blue-900': '#4559BD',
        error: '#B10E1C',
        'error-fill': '#FFEAEC',
        'gold-50': '#FFF6DC',
        'gold-200': '#FFE397',
        'gold-500': '#FFC72E',
        'gold-600': '#F1B000',
        'gold-800': '#785800',
        'green-100': '#DEF0EE',
        'light-grey': '#D9D9D9',
        'neon-dark-green': '#34D399',
        'neon-green': '#B1FD9E',
        'neon-lime': '#C9FB97',
        'neutral-black': '#333333',
        'neutral-dark': '#233E51',
        'neutral-darker': '#23323F',
        'neutral-light': '#E4E9ED',
        'neutral-lighter-alt': '#FBFBFB',
        'neutral-lighter': '#EFF2F4',
        'neutral-primary-alt': '#3E637D',
        'neutral-primary': '#2E516A',
        'neutral-quaternary-alt': '#DDDFE0',
        'neutral-quaternary': '#CFD7DD',
        'neutral-secondary-alt': '#8E9FAC',
        'neutral-secondary': '#647886',
        'neutral-tertiary-alt': '#AFBCC6',
        'neutral-tertiary': '#8EA1AF',
        'orange-100': '#FFB97F',
        'orange-300': '#FF973E',
        'orange-400': '#FF851E',
        'red-50': '#FEF2F2',
        'red-300': '#FCA5A5',
        'red-600': '#DC2626',
        'red-700': '#B91C1C',
        'red-800': '#991B1B',
        'red-900': '#7F1D1D',
        'samples-primary': 'var(--samples-primary)',
        success: '#2C873B',
        'success-fill': '#DFF6DD',
        'theme-dark-alt': '#167066',
        'theme-dark': '#135F56',
        'theme-darker': '#0E463F',
        'theme-light': '#A4D8D2',
        'theme-lighter-alt': '#F0F6F4',
        'theme-lighter': '#CCEAE7',
        'theme-primary': '#197E72',
        'theme-secondary': '#2A8D81',
        'theme-tertiary': '#5CB1A7',
        warning: '#DA3B01',
        'warning-fill': '#FDF4E7',
        'status-warning': '#DD742D',
        white: '#FFFFFF',
        'brand-lighter-alt': '#EFF6F5',
      },
      fontFamily: {
        default: 'Mulish',
        'houschka-rounded': 'HouschkaRounded',
        'houschka-pro': 'HouschkaPro',
      },
      boxShadow: {
        sm: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
        md: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',
        lg: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
        xl: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
        deep: '3px 3px 3px 1px rgba(0, 0, 0, 0.20) inset',
        'low-bottom': 'inset 0px -1px 0px #EEEFEF',
        affordability: '0px 2px 60px 0px rgba(0, 0, 0, 0.05)',
        'inner-sm':
          '0px 1.600000023841858px 3.5999999046325684px 0px rgba(0, 0, 0, 0.13), 0px 0.30000001192092896px 0.8999999761581421px 0px rgba(0, 0, 0, 0.10)',
        'depth-64':
          '0px 25.6px 57.6px 0px rgba(0, 0, 0, 0.22), 0px 4.8px 14.4px 0px rgba(0, 0, 0, 0.18)',
        'dosage-select':
          '0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.10)',
        'drug-interacrions-result': '0px 4px 10px 0px rgba(0,0,0,0.1)',
        'coverage-discover-more-cards':
          '0px 10px 0px 0px rgba(201, 251, 151, 0.50)',
        'coverage-discover-more-cards-hover':
          '0px 5px 0px 0px rgba(201, 251, 151, 0.50)',
        'nps-2': '0px 4px 40px 0px rgba(0, 0, 0, 0.10)',
      },
      borderWidth: {
        0.5: '0.5px',
      },
      spacing: {
        1.5: '0.375rem',
        4.5: '1.125rem',
        13.5: '3.375rem',
        18: '4.5rem',
        45: '11.25rem',
        185: '46.25rem',
      },
      zIndex: {
        60: '60',
        max: '9999',
        min: '-20',
      },
      lineHeight: {
        0: '0',
        5.5: '1.375rem',
      },
      width: {
        4.5: '1.125rem',
        30: '7.5rem',
        46: '11.5rem',
        100: '25rem',
        124: '31rem',
        150: '37.5rem',
      },
      maxWidth: {
        150: '37.5rem',
        185: '46.25rem',
      },
      height: {
        4.5: '1.125rem',
        17: '4.25rem',
        30: '7.5rem',
        66: '16.5rem',
      },
      minHeight: {
        'page-content': 'calc(100vh - 6rem)',
        'available-space': '-webkit-fill-available',
      },
      lineClamp: {
        7: '7',
        8: '8',
        9: '9',
        10: '10',
      },
      tracking: {
        small: '0.1px',
      },
      transitionDuration: {
        600: '600ms',
      },
      opacity: {
        4: '0.04',
        8: '0.08',
        12: '0.12',
        16: '0.16',
        20: '0.20',
        24: '0.24',
        32: '0.32',
        40: '0.40',
        48: '0.48',
        56: '0.56',
        72: '0.72',
      },
      rotate: {
        9: '9deg',
      },
      blur: {
        xs: '3px',
      },
      animation: {
        bounceSideways: 'bounceSideways 3s ease-in-out infinite',
        fadeIn: 'fadeIn 0.3s ease-in-out',
        'collapse-down': 'collapse-down 0.2s ease-out',
        'collapse-up': 'collapse-up 0.2s ease-out',
      },
      keyframes: {
        bounceSideways: {
          '0%, 10%, 100%': {transform: 'translateX(0)'},
          '25%': {transform: 'translateX(2px)'},
          '38%': {transform: 'translateX(2px)'},
          '45%': {transform: 'translateX(-5px)'},
          '48%': {transform: 'translateX(-7px)'},
          '75%': {transform: 'translateX(-7px)'},
          '90%': {transform: 'translateX(1px)'},
        },
        'collapse-down': {
          from: {height: '0px', opacity: '0'},
        },
        'collapse-up': {
          to: {height: '0px', opacity: '0'},
        },
        fadeIn: {
          '0%': {opacity: '0', filter: 'blur(5px)'},
          '100%': {opacity: '1', filter: 'blur(0px)'},
        },
      },
    },
  },
  plugins: [
    function ({addVariant}) {
      addVariant('pivotButton', '& > div > button');
      addVariant('lastPivotButton', '& > div > button:last-child');
    },
  ],
  corePlugins: {preflight: false},
};
