import {useEffect, useMemo, useState, memo} from 'react';
import {buildSuggestedPrompts} from './buildSuggestedPrompts';
import {API_AI_SUBSCRIPTION_KEY, API_URL_CHATBOT} from '@/constants/global';

interface IPrescriberAIProps {
  drugsName: string;
  triggerId?: string;
  suggestedPrompts?: string;
  headerDescription?: string;
  hideHyperlinks?: boolean;
  ratingEnabled?: boolean;
  sharingEnabled?: boolean;
  autoFocus?: boolean;
  defaultExpanded?: boolean;
}

const PrescriberAI = ({
  drugsName = '',
  triggerId,
  suggestedPrompts,
  headerDescription,
  ratingEnabled = false,
  sharingEnabled = false,
  defaultExpanded = false,
  autoFocus = false,
  hideHyperlinks = false,
}: IPrescriberAIProps) => {
  const [component, setComponent] = useState<HTMLElement | null>(null);
  const defaultSuggestedPrompts = useMemo(
    () => suggestedPrompts || buildSuggestedPrompts(drugsName),
    [suggestedPrompts, drugsName],
  );

  useEffect(() => {
    const existingWebComponent = document.querySelector('pp-chatbot-wc');

    if (existingWebComponent && drugsName) {
      // If the Web Component already exists, we set the attribute directly
      existingWebComponent.setAttribute('drugs-names', drugsName);
      return;
    }

    const webComponent = document.createElement('pp-chatbot-wc');
    if (drugsName) {
      webComponent.setAttribute('drugs-names', drugsName);
    }
    webComponent.setAttribute('rating-enabled', ratingEnabled?.toString());
    webComponent.setAttribute('sharing-enabled', sharingEnabled?.toString());
    webComponent.setAttribute('auto-focus', autoFocus?.toString());
    webComponent.setAttribute('ms-service-url', API_URL_CHATBOT);
    webComponent.setAttribute('subscription-key', API_AI_SUBSCRIPTION_KEY);
    webComponent.setAttribute('default-expanded', defaultExpanded?.toString());
    webComponent.setAttribute('trigger-id', triggerId || '');
    webComponent.setAttribute(
      'header-description',
      headerDescription || `Answers for all your ${drugsName} label questions.`,
    );
    webComponent.setAttribute('suggested-prompts', defaultSuggestedPrompts);
    webComponent.setAttribute('max-call-attempts', '0');
    webComponent.setAttribute('copy-enabled', 'true');
    webComponent.setAttribute('hide-hyperlinks', hideHyperlinks.toString());
    webComponent.setAttribute(
      'input-placeholder',
      drugsName
        ? `Ask me anything about ${drugsName}.`
        : 'Ask me anything about a drug.',
    );

    setComponent(webComponent);
  }, [
    drugsName,
    triggerId,
    autoFocus,
    ratingEnabled,
    sharingEnabled,
    defaultExpanded,
    headerDescription,
    hideHyperlinks,
    defaultSuggestedPrompts,
  ]);

  return (
    <div className="md:min-h-[292px] md:py-8">
      <div id="web-component-container" className="text-start md:mt-8">
        {component && (
          <div dangerouslySetInnerHTML={{__html: component.outerHTML}} />
        )}
      </div>
    </div>
  );
};

export default memo(PrescriberAI);
